import React from "react";
import Modal from "react-modal";
import { ThemeProvider } from "styled-components";
import { gusTheme } from "@components/Theme";
import H from "@components/atoms/Typography/Header";
import Icon from "@components/atoms/Icon";
import {
  $ModalHeaderContainer,
  $ModalBodyContainer,
  $ModalHeaderCopyContainer,
  $ModalHeaderCloseButtonContainer,
} from "./Modal.sc";
import hexRgb from "hex-rgb";
import { colors } from "@styles/colors";

interface IModalTemplate {
  header: string;
  withCloseButton?: boolean;
  width?: string;
  height?: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const overlayBackground = hexRgb(colors.baseDarkest, {
  format: "css",
  alpha: 0.7,
});

export const modalStyle = {
  overlay: {
    background: overlayBackground,
    mixBlendMode: "normal",
    zIndex: 99,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "320px",
    height: "auto",
    overflow: "hidden",
    padding: "20px",
    borderRadius: "20px",
  },
};

export const modalStyleWithProps = (width?: string, height?: string) => {
  const newModalStyles = {};
  Object.assign(newModalStyles, modalStyle);
  // @ts-ignore
  if (width) newModalStyles.content.width = width;
  // @ts-ignore
  if (height) newModalStyles.content.height = height;
  return newModalStyles;
};

export const ModalTemplate = ({
  header,
  withCloseButton = true,
  width = "auto",
  height = "auto",
  children,
  onClose,
}: IModalTemplate) => (
  <ThemeProvider theme={gusTheme}>
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={modalStyleWithProps(width, height)}
    >
      <$ModalHeaderContainer>
        <$ModalHeaderCopyContainer>
          <H size="3" color="baseDark">
            {header}
          </H>
        </$ModalHeaderCopyContainer>
        {withCloseButton && (
          <$ModalHeaderCloseButtonContainer>
            <span onClick={onClose}>
              <Icon icon="close" size={24} color="baseDark" />
            </span>
          </$ModalHeaderCloseButtonContainer>
        )}
      </$ModalHeaderContainer>
      <$ModalBodyContainer>{children}</$ModalBodyContainer>
    </Modal>
  </ThemeProvider>
);
