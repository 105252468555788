import styled from "styled-components";

interface IRecaptchaStyle {
  hideOnMobile?: boolean;
}

export const $RecaptchaStyle = styled.div<IRecaptchaStyle>`
  .grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    right: 4px !important;
    bottom: 50px !important;
  }
  .grecaptcha-badge:hover {
    width: 256px !important;
  }
  ${({ hideOnMobile = false }) =>
    hideOnMobile &&
    `
      @media (max-width: 990px) {
        display: none;
        .grecaptcha-badge {
          opacity: 0;
          display: none;
        }
      }
    `}
`;
