import * as React from "react";
const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps");
import locationPin from "../../../../assets/icons/location-pin-grey.svg";
import locationPinBlue from "../../../../assets/icons/location-pin-blue.svg";
import { colors } from "@styles/colors";
declare var google;

export const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&v=3.exp&types=(establishment)&libraries=places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  withScriptjs,
  lifecycle({
    componentWillMount() {
      this.setState({
        places: [],
        googleData: [],
      });
    },
    componentDidMount() {
      const input = document.getElementById("googlestandalone");
      const options = {
        types: ["establishment"],
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        this.setState({ googleData: [place] });
        this.props.googleData([place]);
      });
    },
  })
)((props) => {
  return (
    <div data-standalone-searchbox="">
      <input
        name="title"
        type="text"
        id="googlestandalone"
        placeholder={
          props.placeholderText === undefined
            ? "Type Name Here"
            : props.placeholderText
        }
        onLoad={props.onSearchBoxMounted}
        autoComplete="off"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "5px",
          backgroundImage:
            props.googleData.length > 0
              ? `url(${locationPinBlue})`
              : `url(${locationPin})`,
          boxSizing: `border-box`,
          border: `2px solid ${colors.lightGray}`,

          width: `100%`,
          height: `48px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          fontSize: `18px`,
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: "normal",
          lineHeight: "28px",
          paddingLeft: "40px",
          alignItems: "left",
          color: colors.gray,
        }}
      />
    </div>
  );
});

export default PlacesWithStandaloneSearchBox;
