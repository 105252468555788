import styled from "styled-components";

export const $ModalHeaderContainer = styled.div`
  padding: 0px 32px;
`;

export const $ModalHeaderCopyContainer = styled.div`
  float: left;
  margin-top: 32px;
  margin-bottom: 13px;
`;

export const $ModalHeaderCloseButtonContainer = styled.div`
  float: right;
  margin-top: 32px;
  cursor: pointer;
`;

export const $ModalBodyContainer = styled.div`
  clear: both;
`;
